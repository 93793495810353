.App {
  display: "flex";
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiBox-root {
  padding: 0px;
}

.MuiToolbar-gutters {
  padding-left: 4xp;
}

.MuiTypography-root {
  font-family: "Nunito Sans";
}

.MuiTextField-root {
  font-family: "Nunito Sans";
}

.MuiDivider-middle {
  margin-left: 4px;
  margin-right: 8px;
}

.MuiListItemIcon-root {
  min-width: 30px;
  padding-bottom: 0px;
}

.MuiAutocomplete-popper {
  margin-top: 16px;
}

.MuiAutocomplete-listbox {
  padding-left: 20px;
}

.dashboardIcon {
  color: #8f949c;
  width: 24px;
  height: 24px;
  padding-right: 4px;
}

.dashboardAllIcon {
  width: 24px;
  height: 24px;
  padding-right: 4px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
